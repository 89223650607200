import React, { useEffect, useRef, useState } from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display4 } from '@jsluna/typography'
import { FilledButton, TextButton, CancelButton } from '@jsluna/button'
import databasetableApi from '../../api/databasetableApi'
import { useApiClient } from '../../context/AppContext'

const DatabaseTables = () => {
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)

  const apiClient = useApiClient()

  const exportToCsv = () => {

    databasetableApi
    .getallcontractordetails(apiClient)
    .then(response => {

        let headers = ['Id,ContractorName,ContractorEmailId,EscalationLevel1,EscalationLevel2,EscalationLevel3,EscalationLevel4,EscalationLevel5,IsEnabled,ContractManager,EscalationCategory,EscalationRegion,EscalationEmailCc,EscalationEmailBcc,HeadOfContractAndPerformance,DirectorOfFM']
    
    let csvdetails = response.reduce((acc :any, contractor) => {
      const { id, contractorName, contractorEmailId, escalationLevel1, escalationLevel2, escalationLevel3, escalationLevel4, escalationLevel5, isEnabled, contractManager, escalationCategory, escalationRegion, escalationEmailCc, escalationEmailBcc, headOfContractAndPerformance, directorOfFM } = contractor
      acc.push([id, contractorName, contractorEmailId, escalationLevel1, escalationLevel2, escalationLevel3, escalationLevel4, escalationLevel5, isEnabled, contractManager, escalationCategory, escalationRegion, escalationEmailCc, escalationEmailBcc, headOfContractAndPerformance, directorOfFM].join(','))
      return acc
    }, [])

    var data =[...headers, ...csvdetails].join('\n')

    const blob = new Blob([data], { type: 'text/csv' })
    const a = document.createElement('a')
    a.download = 'ContractorDetails.csv'
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()

  })
  .catch(error => {
    console.log(error)
    setErrorAlert('Contacts export Failed.')
    setSuccessAlert(false)
  })

}

  return (
    <Container className='container'>
      <TextButton onClick={exportToCsv}>Export Contract Escalation Details </TextButton>
    </Container>
  )
}

export default DatabaseTables