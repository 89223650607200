import React from "react";
import { useEffect, useState, useRef } from "react";
import { useApiClient } from "../../context/AppContext";
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Select, SelectField } from "@jsluna/form";
import { Display1 } from '@jsluna/typography'
import { DDLBinding, FMRegionDetails, ZoneSearchItem } from '../../types/Entities/SiteCareManager'
import siteCareManagerAPi from '../../api/siteCareManagerApi'
import { Card } from '@jsluna/card'
import { FilledButton, OutlinedButton, TextInputField } from '@jsluna/react'
import ConfirmationModal from '../Common/ContractorForm/ConfirmationModal'
import FormAlerts from '../Common/ContractorForm/FormAlerts'

const AssignRegions = () => {
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)
  const [fmzonedetails, setzonedetails] = useState([] as ZoneSearchItem[])
  const [fmregiondetails, setfmregiondetails] = useState({} as FMRegionDetails)
  const [showcontrols, setshowcontrols] = useState(false)
  const [modalState, setModalState] = useState<'savescm' | 'discard' | false>(false)
  const [zonedropdown, setzonedropdown] = useState('')
  const [, updateState] = useState({});

  // const useForceUpdate = () => {
  //   const [, updateState] = useState({});
  //   return () => {
  //     updateState({});
  //   }
  // };

  // const forceUpdate = useForceUpdate();

  const apiClient = useApiClient()

  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  useEffect(() => {
    const zoneddl = async () => { setzonedetails(await loadFMZonedetails()) }
    zoneddl();
  }, fmzonedetails);

  const fmZoneChangeEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    debugger;
    var zid = event.target.value;
    setzonedropdown(zid);
    if (zid) {
      loadFMRegiondetails(zid);
    }
    else {
      setshowcontrols(false);
    }
  }

  const fmRegionChangeEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    debugger;
    const element = event.target as HTMLSelectElement;
    const fmlist = fmregiondetails;

    if (element.id === 'headoffmdropdown') {
      fmlist.fmZoneDetail[0]['lscM_Id'] = element.value;
    }
    else {
      const fmregionlist = [...fmregiondetails.fmRegionDetail];
      let itemindex = fmregionlist.findIndex(i => i.id === element.id);
      fmregionlist[itemindex]['scM_Id'] = element.value;
      fmlist.fmRegionDetail = fmregionlist;
    }

    setfmregiondetails(fmlist);
   // forceUpdate();
   updateState({});
  }

  const loadFMZonedetails = async (): Promise<ZoneSearchItem[]> => {
    return siteCareManagerAPi
      .getFMZoneDetails(apiClient)
      .then((response) =>
        response.map((item) => ({
          label: item.fmZone,
          value: item.id
        }))
      )
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        scrollToTop()
        const event = new Event('click', { bubbles: true })
        document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
        return Promise.resolve([])
      })
  }

  const handleScrollModal = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }

  const handleSaveForm = () => {
    setModalState(false)
    siteCareManagerAPi
      .UpdateRegionDetailsDetails(apiClient, fmregiondetails)
      .then(response => {
        debugger;
        if (response.ok) {
          setSuccessAlert('Assign people to zones and regions have been updated successfully.')
          scrollToTop();
          setErrorAlert(false)
        }
        else {
          throw new Error('Invalid response.')
        }
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('update people to zones and regions failed.')
        setSuccessAlert(false)
        scrollToTop()
      })
  }

  const handleDiscardForm = () => {
    setModalState(false);
    setshowcontrols(false);
    setzonedropdown('');
    clearAlerts();
  }

  const handleSaveButtonClick = () => {
    setModalState('savescm');
  }

  const clearAlerts = () => {
    setErrorAlert(false)
    setSuccessAlert(false)
  }

  const loadFMRegiondetails = (zoneid: string) => {
    siteCareManagerAPi
      .getFMRegionDetails(apiClient, zoneid)
      .then((response) => {
        debugger;
        setshowcontrols(true);
        setfmregiondetails(response);
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        const event = new Event('click', { bubbles: true })
        document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
        return Promise.resolve([])
      })
  }

  return (
    <>
      <ConfirmationModal
        showModal={modalState}
        onClose={() => setModalState(false)}
        onSave={handleSaveForm}
        onDiscard={handleDiscardForm}
      />

      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />

      <Card className='contractorForm'>        
      <h5>Assign people to zones and regions</h5>
        <GridWrapper verticalAlign='center'>
          <GridItem className='formSelectGridAlign ln-u-center-block' size={{ xl: '1/4', ss: '1/3', default: '1/1' }}>
            <Display1>FM Zone</Display1>
          </GridItem>
          <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formInputMaxWidth'>
            <SelectField name='fmzone' options={fmzonedetails} value={zonedropdown} onFocus={() => clearAlerts()}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => fmZoneChangeEvent(e)} />
          </GridItem>
          <GridItem size={{ xl: '5/12', ss: '0', default: '1/1' }}><></></GridItem>

          {
            showcontrols && <>
              <GridItem className='formSelectGridAlign ln-u-center-block' size={{ xl: '1/4', ss: '1/3', default: '1/1' }}>
                <Display1 className='ln-u-flush-left'>Head of FM</Display1>
              </GridItem>
              <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formInputMaxWidth'>
                <SelectField name='headoffmdropdown' value={fmregiondetails.fmZoneDetail[0].lscM_Id}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => fmRegionChangeEvent(e)}
                  onFocus={() => clearAlerts()}
                  options={fmregiondetails.lscm.map((item) => ({
                    label: item.label,
                    value: item.value
                  }))}
                />
              </GridItem>
              <GridItem size={{ xl: '5/12', ss: '0', default: '1/1' }}><></></GridItem>

              {
                fmregiondetails.fmRegionDetail.map((region) => {
                  return (
                    <>
                      <GridItem className='formSelectGridAlign ln-u-center-block' size={{ xl: '1/4', ss: '1/3', default: '1/1' }}>
                        <Display1 className='ln-u-flush-left'>{region.fmRegion}</Display1>
                      </GridItem>
                      <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formInputMaxWidth'>
                        <SelectField name={region.id} onFocus={() => clearAlerts()}
                         value={region.scM_Id}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => fmRegionChangeEvent(e)}
                          options={
                          fmregiondetails.scm.map((item) => ({
                            label: item.label,
                            value: item.value
                          }))}
                          />
                      </GridItem>
                      <GridItem size={{ xl: '5/12', ss: '0', default: '1/1' }}><></></GridItem>

                    </>
                  );
                })
              }

            </>
          }

          {
            showcontrols && <>
          <GridItem size={{ xl: '1/4', ss: '1/3', default: '0' }}><></></GridItem>
          <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formInputMaxWidth'>
            <div className='formButtonGroup'>
              <OutlinedButton onClick={() => setModalState('discard')} onScroll={handleScrollModal} size='60px' className='formDiscardButton' >Discard</OutlinedButton>
              <FilledButton onClick={handleSaveButtonClick} onScroll={handleScrollModal} size='60px' className='formSaveButton' >Save</FilledButton>
            </div>
          </GridItem>
          </>
          }

        </GridWrapper>
      </Card>
    </>
  )
}

export default AssignRegions;