import ApiClient from './ApiClient'
import { FMRegionDetails,FMZoneDetails, SCMDetails } from '../types/Entities/SiteCareManager'

const getFMRegionDetails = async (apiClient: ApiClient, zoneid: string):
  Promise<FMRegionDetails> => {
  return apiClient
    .get<FMRegionDetails>(`SiteCareManager/getregionbyzoneid?zoneid=${zoneid}`)
    .catch(error => { throw error })
}

const getFMZoneDetails = async (apiClient: ApiClient):
  Promise<Array<FMZoneDetails>> => {
  return apiClient
    .get<Array<FMZoneDetails>>(`SiteCareManager/getallzones?`)
    .catch(error => { throw error })
}

const getSCMUsersDetails = async (apiClient: ApiClient):
  Promise<Array<SCMDetails>> => {
  return apiClient
    .get<Array<SCMDetails>>(`SiteCareManager/getscmusers?`)
    .catch(error => { throw error })
}

const UpdateSCMUserDetails = async (apiClient: ApiClient, payload: SCMDetails[]):
  Promise<Response> => {
  return apiClient
    .post('SiteCareManager/updatescmusers', JSON.stringify(payload))
    .catch(error => { throw error })
}

const UpdateRegionDetailsDetails = async (apiClient: ApiClient, payload: FMRegionDetails):
  Promise<Response> => {
  return apiClient
    .post('SiteCareManager/updateregiondetails', JSON.stringify(payload))
    .catch(error => { throw error })
}


const siteCareManagerApi = { 
  getFMRegionDetails,
  getFMZoneDetails,
  getSCMUsersDetails,
  UpdateSCMUserDetails,
  UpdateRegionDetailsDetails
}

export default siteCareManagerApi