import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { InteractionType } from '@azure/msal-browser'
import { useMsalAuthentication, AuthenticatedTemplate, useMsal } from '@azure/msal-react'

import './App.css'
import './sass/index.scss'
import { AppContext } from './context/AppContext'
import Constants from './utils/constants'
import HomePage from './components/HomePage'
import Unauthorised from './components/Unauthorised'
import BranchLocator from './components/BranchLocator'
import DatabaseTables from './components/DatabaseTables'
import ContractorContact from './components/ContractorContact'
import ContractorManagement from './components/ContractorManagement'
import NewContractor from './components/NewContractor'
import AssignRegion from './components/AssignRegions'
import SCMPeopleList from './components/SCMPeopleList'

interface Claims {
  roles: string[]
}

const App = () => {
  const msal = useMsal()
  const context = useContext(AppContext)
  const [claims, setClaims] = useState({ roles: [] } as Claims)
  const request = useMemo(() => ({ scopes: [context?.apiScope ?? ''] }), [context]) 

  const { error, login, result } = useMsalAuthentication(InteractionType.Silent, request)

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, request)
    }
  }, [error, login, request])

  useEffect(() => {
    if (result && result.idTokenClaims) {
      setClaims(result.idTokenClaims as Claims)
    }
    else if (result === null && msal.accounts.length > 0) {
      setClaims(msal.accounts[0].idTokenClaims as Claims)
    }
  }, [result, msal.accounts])

  const generateRouteChildren = (roles: string[]) => {
    if (roles && roles.length > 0) {
      if (roles.includes(Constants.AdminRole)) {
        return [
          { path: '*', element: <Navigate to='/' /> },
          { path: '/', element: <ContractorContact /> },
          { path: 'contractor-contact', element: <ContractorContact /> },
          { path: 'contractor-management', element: <ContractorManagement /> },
          { path: 'new-contractor', element: <NewContractor /> },
          { path: 'database-tables', element: <DatabaseTables /> },
          { path: 'branch-locator', element: <BranchLocator /> },
          {path: 'assign-region', element: <AssignRegion />},
          {path: 'SCMPeopleList',element:<SCMPeopleList/>}
        ]
      }
      else if (roles.includes(Constants.ContractorManagerRole)) {
        return [
          { path: '*', element: <Navigate to='/' /> },
          { path: '/', element: <ContractorContact /> },
          { path: 'contractor-contact', element: <ContractorContact /> }
        ]
      }
    }
    else {
      return [
        { path: '*', element: <Navigate to='/' /> },
        { path: '/', element: <Unauthorised /> }
      ]
    }
  }

  const routes = {
    path: '/',
    element: <HomePage roles={claims.roles} />,
    children: generateRouteChildren(claims.roles ?? null),
  }

  const routing = useRoutes([routes])

  return (
    <AuthenticatedTemplate>{routing}</AuthenticatedTemplate>
  )
}

export default App