import { FC, ReactNode, createContext, useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import ApiClient from '../api/ApiClient'

type AppContextType = {
  apiClient: ApiClient
  apiScope: string
}

export const AppContext = createContext<AppContextType | null>(null)

type AppProviderProps = {
  apiScope: string
  apiBaseUrl: string
  children?: ReactNode
}

export const AppProvider: FC<AppProviderProps> = ({apiScope, apiBaseUrl, children}) => {
  const { instance } = useMsal()
  const apiClient = new ApiClient(instance, apiScope, apiBaseUrl)

  return (
    <AppContext.Provider value={{apiClient, apiScope}}>
      {' '}{children}{' '}
    </AppContext.Provider>
  )
}

export function useApiClient() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApiClient must be used within an AppProvider')
  }

  return context.apiClient
}