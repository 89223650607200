import { Modal, ModalHeading } from '@jsluna/react'
import { Body1 } from '@jsluna/typography'
import { FilledButton, CancelButton } from '@jsluna/button'

interface Props {
  onClose: () => void
}

const ThanksMessage = ({ onClose }: Props) => {
  return (
    <Modal small hideCloseButton open={true} headingId="info-modal">
      <div className="ln-u-text-align-right">
        <CancelButton
          className="feedback-closeButton"
          onClick={onClose}
        />
      </div>
      <ModalHeading element="h4">
        <div className="ln-u-text-align-center">
          <span
            aria-label="clapping hands"
            role="img"
            className="feedback-emoji"
          >
            👏
          </span>
          <br />
          Thank you
        </div>
      </ModalHeading>
      <Body1>
        Thanks for taking the time to send your comments. We rely on hearing
        from our colleagues to continue to prioritise making improvements in
        the coming releases.
      </Body1>
      <FilledButton
        fullWidth
        className="feedback-submitButton ln-u-margin-top*3"
        onClick={onClose}
      >
        Close
      </FilledButton>
    </Modal>
  )
}

export default ThanksMessage