import { Alert, AlertIcon } from '@jsluna/react'
import { ErrorCircle } from '@jsluna/icons'

type Props = {
  showError: false | string
  showSuccess: false | string
  onClose: () => void
}

const FormAlerts = (props: Props) => {
  return (
    <>
      <Alert variant='error' open={props.showError !== false} onClose={props.onClose} squared>
        <AlertIcon>
          <ErrorCircle aria-label='Error' role='img' />
        </AlertIcon>
        {props.showError}
      </Alert>
      <Alert variant='success' open={props.showSuccess !== false} onClose={props.onClose} squared>
        {props.showSuccess}
      </Alert>
    </>
  )
}

export default FormAlerts