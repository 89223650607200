import ApiClient from './ApiClient'
import { NewContractorType } from '../types/Entities/NewContractorType'

const addContractor = async (apiClient: ApiClient, payload: NewContractorType):
  Promise<Response> => {
  return apiClient
    .post('NewContractor', JSON.stringify(payload))
    .catch(error => { throw error })
}

const newContractorApi = { addContractor }

export default newContractorApi