import { AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser'

export default class ApiClient {
  private instance: IPublicClientApplication
  private apiScope: string
  private baseUrl: string

  constructor(
    instance: IPublicClientApplication,
    apiScope: string,
    baseUrl: string,
  ) {
    this.instance = instance
    this.apiScope = apiScope
    this.baseUrl = baseUrl
  }

  public async getAccessToken(): Promise<string | null> {
    const accounts = this.instance.getAllAccounts()

    if (accounts.length === 0) {
      return null
    }

    const account = accounts[0]

    try {
      const authResult: AuthenticationResult = await this.instance
        .acquireTokenSilent({
          account,
          scopes: [this.apiScope],
        })

      return authResult.accessToken
    }
    catch (error) {
      console.log(error)
      return null
    }
  }

  public async get<T>(endpoint: string): Promise<T> {
    const accessToken = await this.getAccessToken()
    const url = new URL(endpoint, this.baseUrl)

    if (!accessToken) {
      throw new Error("Access token not available")
    }

    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Accept': '*',
        'Content-Type': 'application/json',
      })
    }

    try {
      const response = await fetch(url, requestOptions)
      if (response && response.ok) {
        return await response.json() as T
      }
      throw new Error(response.statusText)
    }
    catch (error) {
      throw error
    }
  }

  public async post(endpoint: string, payload: any) {
    const accessToken = await this.getAccessToken()
    const url = new URL(endpoint, this.baseUrl)

    if (!accessToken) {
      throw new Error("Access token not available")
    }

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: payload,
    }

    try {
      const response = await fetch(url, requestOptions)
      return response
    }
    catch (error) {
      throw error
    }
  }
}