import { PropsWithChildren } from 'react'
import { GridItem } from '@jsluna/grid'
import { Display1 } from '@jsluna/typography'

type Props = {
  label: string
}

const ContractorFormField = (props: PropsWithChildren<Props>) => {
  return (
    <>
      <GridItem className='formGridAlign ln-u-center-block' size={{ xl: '1/4', ss: '1/3', default: '1/1' }}>
        <Display1>{props.label}</Display1>
      </GridItem>
      <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formInputMaxWidth'>
        {props.children}
      </GridItem>
      <GridItem size={{ xl: '5/12', ss: '0', default: '1/1' }}><></></GridItem>
    </>
  )
}

export default ContractorFormField