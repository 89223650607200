import Modal from '../../Modal'

interface Props {
  showModal: 'save' | 'discard' | 'savescm' | false
  onClose: () => void
  onSave: () => void
  onDiscard: () => void
}

const ConfirmationModal = (props: Props) => {

  var modalHeading = '', modalBody = '', modalPrimaryBtnTitle = '', modalPrimaryBtnClickHandler = () => { }
  
  if (props.showModal === 'save') {
    modalHeading = 'Save Changes'
    modalBody = 'Do you want to save changes to the contact details?'
    modalPrimaryBtnTitle = 'Save'
    modalPrimaryBtnClickHandler = props.onSave
  }
  else if (props.showModal === 'discard') {
    modalHeading = 'Discard Changes'
    modalBody = 'Do you want to discard changes and clear the form?'
    modalPrimaryBtnTitle = 'Discard'
    modalPrimaryBtnClickHandler = props.onDiscard
  }
  else if (props.showModal === 'savescm') {
    modalHeading = 'Save Changes'
    modalBody = 'Do you want to save changes?'
    modalPrimaryBtnTitle = 'save'
    modalPrimaryBtnClickHandler = props.onSave
  }

  return (
    <Modal
      isOpen={props.showModal !== false}
      heading={modalHeading}
      body={modalBody}
      primaryButtonTitle={modalPrimaryBtnTitle}
      secondaryButtonTitle='Close'
      handlePrimaryButtonClick={modalPrimaryBtnClickHandler}
      handleSecondaryButtonClick={props.onClose}
    />
  )
}

export default ConfirmationModal