import React from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display4 } from '@jsluna/typography'

const Unauthorised = () => {
  return (
    <Container className='container'>
      <Card className='main-content'>
        <Display4>You don't have the required access to visit this site.</Display4>
      </Card>
    </Container>
  )
}

export default Unauthorised