import React, { useEffect, useRef, useState } from 'react'

import ContractorForm from '../Common/ContractorForm'

import newContractorApi from '../../api/newContractorApi'
import { ContractorFormData } from '../../types/FormTypes'
import { useApiClient } from '../../context/AppContext'
import { NewContractorType } from '../../types/Entities/NewContractorType'

const NewContractor = () => {
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)

  const myFormRef: any = useRef()

  const apiClient = useApiClient()

  useEffect(() => {
    myFormRef.current?.setContractorEmail({ value: '', error: '' })
    myFormRef.current?.setManagerEmail({ value: '', error: '' })
    myFormRef.current?.setAutomation(false)
    myFormRef.current?.setEscalationValue(0, [])
    myFormRef.current?.setEscalationValue(1, [])
    myFormRef.current?.setEscalationValue(2, [])
    myFormRef.current?.setEscalationValue(3, [])
    myFormRef.current?.setEscalationValue(4, [])
  })

  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  const handleSaveForm = (data: ContractorFormData) => {
    var payload = {} as NewContractorType
    payload.contractorName = data.contractorName.value.trim()
    payload.contractorEmailId = data.contractorEmail.value
    payload.contractorManagerEmail = data.managerEmail.value
    payload.escalationLevel1 = data.escalations[0].value.join(',')
    payload.escalationLevel2 = data.escalations[1].value.join(',')
    payload.escalationLevel3 = data.escalations[2].value.join(',')
    payload.escalationLevel4 = data.escalations[3].value.join(',')
    payload.escalationLevel5 = data.escalations[4].value.join(',')
    payload.isEnabled = data.automation

    newContractorApi
      .addContractor(apiClient, payload)
      .then(response => {
        if (response.ok) {
          setSuccessAlert('Contractor have been added successfully.')
          setErrorAlert(false)
          resetPage()
          scrollToTop()
        }
        else {
          throw new Error('Invalid response.')
        }
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('Adding Contractor Contacts Failed.')
        setSuccessAlert(false)
        scrollToTop()
      })
  }

  const resetPage = () => {
    setErrorAlert(false)
    myFormRef.current?.clearForm()

    const event = new Event('click', { bubbles: true })
    document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='Clear the field']")?.dispatchEvent(event)
    document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
  }

  const clearAlerts = () => {
    setErrorAlert(false)
    setSuccessAlert(false)
  }

  return (
    
    <ContractorForm
      ref={myFormRef}
      contractorName={true}
      contractorEmail={true}
      contractorEscalations={true}
      managerEmail={true}
      automation={true}
      onSave={handleSaveForm}
      errorAlert={errorAlert}
      successAlert={successAlert}
      onAlertClose={clearAlerts}
    />

    
  )
}

export default NewContractor