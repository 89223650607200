import React, { useState, useRef } from 'react'
import { AsyncAutocompleteField } from '@jsluna/react'
import { Search } from '@jsluna/icons'

import ContractorFormField from '../Common/ContractorForm/ContractorFormField'
import ContractorForm from '../Common/ContractorForm'

import { ContractorSearchItem } from '../../types/Entities/ContractorEscalation'
import { ContractorManagementType } from '../../types/Entities/ContractorManagementType'
import { ContractorFormData } from '../../types/FormTypes'
import contractorContactsApi from '../../api/contractorContactsApi'
import contractorManagementApi from '../../api/contractorManagementApi'
import { useApiClient } from '../../context/AppContext'

const ContractorManagement = () => {
  const [selectedContractor, setSelectedContractor] = useState({ id: '', contractorName: '' } as ContractorManagementType)
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)
  const [automationDisabled, setAutomationDisabled] = useState(false)

  const myFormRef: any = useRef()

  const apiClient = useApiClient()

  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  const loadContractorSearch = async (value: string): Promise<ContractorSearchItem[]> => {
    return contractorContactsApi
      .findContractorContacts(apiClient, value)
      .then((response) =>
        response.map((item) => ({
          label: item.contractorName,
          value: item.id,
        }))
      )
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        scrollToTop()
        const event = new Event('click', { bubbles: true })
        document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
        return Promise.resolve([])
      })
  }

  const handleContractorSelect = (val: ContractorSearchItem) => {
    if (val === null) {
      resetPage()
    }
    else {
      contractorManagementApi
        .getContractorManagement(apiClient, val.value)
        .then((response) => {
          if (response && response.id) {
            setSelectedContractor(response)
            myFormRef.current?.setManagerEmail({ value: response.contractorManagerEmail ?? '', error: '' })
            myFormRef.current?.setAutomation(response.isEnabled ?? false)
            const IsAnyFieldEmpty = !response.escalationLevel1 || !response.escalationLevel2 || !response.escalationLevel3 || !response.escalationLevel4 || !response.escalationLevel5
            setAutomationDisabled(IsAnyFieldEmpty)
          }
          else {
            throw new Error('Invalid response.')
          }
        })
        .catch(error => {
          console.log(error)
          setErrorAlert('Something went wrong!')
          scrollToTop()
        })
    }
  }

  const handleSaveForm = (data: ContractorFormData) => {
    var payload = selectedContractor
    payload.contractorManagerEmail = data.managerEmail.value
    payload.isEnabled = data.automation
    
    contractorManagementApi
      .updateContractorManagement(apiClient, payload)
      .then(response => {
        if (response.ok) {
          setSuccessAlert('Contractor details have been updated successfully.')
          setErrorAlert(false)
          resetPage()
          scrollToTop()
        }
        else {
          throw new Error('Invalid response.')
        }
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('Updating Contractor Failed.')
        setSuccessAlert(false)
        scrollToTop()
      })
  }

  const resetPage = (flag: boolean = true) => {
    setErrorAlert(false)
    setSelectedContractor({ id: '', contractorName: '' } as ContractorManagementType)
    setAutomationDisabled(false)
    if (flag) myFormRef.current?.clearForm()

    const event = new Event('click', { bubbles: true })
    document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='Clear the field']")?.dispatchEvent(event)
    document.querySelector("button.ln-c-button.ln-c-input-group__action, button[aria-label='close menu']")?.dispatchEvent(event)
  }

  const clearAlerts = () => {
    setErrorAlert(false)
    setSuccessAlert(false)
  }

  var automation : boolean | 'disabled' = false
  if (selectedContractor.id !== '') {
    automation = automationDisabled ? 'disabled' : true
  }
  
  return (
    <ContractorForm
      ref={myFormRef}
      managerEmail={selectedContractor.id !== ''}
      automation={automation}
      onSave={handleSaveForm}
      errorAlert={errorAlert}
      successAlert={successAlert}
      onAlertClose={clearAlerts}
      onClear={() => resetPage(false)}
    >
      <ContractorFormField label='Contractor Name'>
        <AsyncAutocompleteField
          label=''
          role='search'
          className='formInput'
          name='contractor-search'
          onSelect={handleContractorSelect}
          onFocus={() => clearAlerts()}
          onChange={() => clearAlerts()}
          loadOptions={loadContractorSearch}
          icon={<Search />}
        />
      </ContractorFormField>
    </ContractorForm>
  )
}
export default ContractorManagement
