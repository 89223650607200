import ApiClient from './ApiClient'
import { ContractorEscalation } from '../types/Entities/ContractorEscalation'

const findContractorContacts = async (apiClient: ApiClient, searchKey: string):
  Promise<Array<ContractorEscalation>> => {
  return apiClient
    .get<Array<ContractorEscalation>>(`ContractorContacts/find?searchKey=${searchKey}`)
    .catch(error => { throw error })
}

const getContractorContacts = async (apiClient: ApiClient, id: string):
  Promise<ContractorEscalation> => {
  return apiClient
    .get<ContractorEscalation>(`ContractorContacts/get?id=${id}`)
    .catch(error => { throw error })
}

const updateContractorContacts = async (apiClient: ApiClient, payload: ContractorEscalation):
  Promise<Response> => {
  return apiClient
    .post('ContractorContacts', JSON.stringify(payload))
    .catch(error => { throw error })
}

const contractorContactsApi = { 
  getContractorContacts, 
  findContractorContacts, 
  updateContractorContacts 
}

export default contractorContactsApi