import React from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display4 } from '@jsluna/typography'

const BranchLocator = () => {
  return (
    <Container className='container'>
      <Card className='main-content'>
        <Display4>Branch Locator Page</Display4>
      </Card>
    </Container>
  );
}

export default BranchLocator