import React from 'react'
import { GridItem } from '@jsluna/grid'
import { Display1 } from '@jsluna/typography'

import MultipleTextInput from '../../MultipleTextInput'
import { EscalationsFormType } from '../../../../types/FormTypes'

type Props = {
  escalations: EscalationsFormType
  onUpdateValue: (index: number, value: string[]) => void
  onUpdateError: (index: number, error: string) => void
}

const ContractorEscalationForm = ({ escalations, onUpdateValue, onUpdateError }: Props) => {
  return (
    <>
      {escalations && escalations.length > 0 ?
        escalations.map((escalation, index) => {
          var i = index+1
          return (<>
            <GridItem className='formGridAlign' size={{ xl: '1/4', ss: '1/3', default: '1/1' }}>
              <Display1 className='ln-u-flush-left'>Level {i}</Display1>
            </GridItem>
            <GridItem size={{ xl: '1/3', ss: '2/3', default: '1/1' }} className='formMultipleInput'>
              <MultipleTextInput
                values={escalation.value}
                maxValues={3}
                placeholderText={`Enter Level ${i} Email Contact...`}
                name={`level-${i}-input-field`}
                updateHandler={(values) => onUpdateValue(index, values)}
                parentError={escalation.error}
                clearParentError={() => onUpdateError(index, '')}
              />
            </GridItem>
            <GridItem size={{ xl: '5/12', ss: '0', default: '1/1' }}><></></GridItem>
          </>)
        }) : <></>
      }
    </>
  )
}

export default ContractorEscalationForm