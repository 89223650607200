import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'

import { AppProvider } from './context/AppContext'
import { config } from './utils/config'
import App from './App'

const msalConfig = {
  auth: {
    clientId: config?.clientId ?? '',
    authority: config?.clientAuthority ?? '',
    redirectUri: config?.redirectUrl ?? ''
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
}

const msalInstance = new PublicClientApplication(msalConfig)

var reactPlugin = new ReactPlugin()
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
})
appInsights.loadAppInsights()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AppProvider apiScope={config?.scope ?? ''} apiBaseUrl={config?.apiBaseUrl ?? ''}>
        <Router>
          <AppInsightsErrorBoundary onError={() => <h1>Something went wrong</h1>} appInsights={reactPlugin}>
            <App />
          </AppInsightsErrorBoundary>
        </Router>
      </AppProvider>
    </MsalProvider>
  </React.StrictMode>
)