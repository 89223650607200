import ApiClient from './ApiClient'
import { FeedbackType } from '../types/Entities/FeedbackType'

const feedbackApi = async (apiClient: ApiClient, payload: FeedbackType):
  Promise<Response> => {
  return apiClient
    .post('Feedback', JSON.stringify(payload))
    .catch(error => { throw error })
}

export default feedbackApi