import { useMsal } from '@azure/msal-react'

const SignOutButton = () => {
    const { instance } = useMsal()

    const handleSignOut = () => {
        instance.logoutRedirect()
    }

    return (
        <div className='ln-c-topbar__item'>
            <a
                className='ln-o-bare-link ln-c-topbar__text logOut'
                href='#signout'
                id='logout'
                key='logout'
                aria-selected='true' role='tab'
                aria-controls='logout'
                onClick={handleSignOut}
            >Logout</a>
        </div>
    )
}

export default SignOutButton