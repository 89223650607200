import React, { useImperativeHandle, useState } from 'react'
import { Modal, ModalHeading } from '@jsluna/react'
import { Body1 } from '@jsluna/typography'
import { TextAreaField } from '@jsluna/form'
import { FilledButton, TextButton, CancelButton } from '@jsluna/button'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { ErrorCircle } from '@jsluna/icons'
import ThanksMessage from './ThanksMessage'
import feedbackApi from '../../api/feedbackApi'
import { FeedbackType } from '../../types/Entities/FeedbackType'
import { useApiClient } from '../../context/AppContext'

const satisfactionLevels = [
  [1, '😰', 'nervous face', 'Very dissatisfied'],
  [2, '😩', 'veary face', 'Dissatisfied'],
  [3, '😐', 'neutral face', 'Neutral'],
  [4, '😄', 'smiley face', 'Satisfied'],
  [5, '😍', 'heart face', 'Very satisfied'],
]

interface Props {
  headerText: string,
  bodyText: string
}

const Feedback = React.forwardRef(({ headerText, bodyText }: Props, ref) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [feedback, setFeedback] = useState({ rating: '', comment: '' })

  const apiClient = useApiClient()

  const submitForm = async () => {
    feedbackApi(apiClient, { rating: +feedback.rating, comments: feedback.comment } as FeedbackType)
      .then(res => {
        setFormSubmitted(true)
      })
  }

  const show = () => {
    setIsOpen(true)
  }

  useImperativeHandle(ref, () => ({ show }), [isOpen, setIsOpen])

  const handleClose = () => {
    setFormSubmitted(false)
    setIsOpen(false)
    setShowError(false)
    setDisabledButton(false)
    setFeedback({ rating: '', comment: '' })
  }

  const setRating = (ratingLevel: string) => {
    setFeedback(prevState => ({ ...prevState, rating: ratingLevel }))
    setShowError(false)
  }

  const handleChange = (input?: any) => {
    if (input !== null && input.target !== null) {
      setFeedback(prevState => ({ ...prevState, comment: input.target.value }))
    }
  }

  const handleValidation = () => {
    if (feedback.rating === '') {
      setShowError(true)
    } else {
      setDisabledButton(true)
      submitForm()
    }
  }

  if (formSubmitted) {
    return <ThanksMessage onClose={handleClose} />
  }

  return (
    <Modal small alert hideCloseButton open={isOpen} headingId="dialog-modal">
      <GridItem size="9/10" className="ln-u-hard">
        <ModalHeading element="h4">
          {headerText}
        </ModalHeading>
      </GridItem>
      <GridItem size="1/10" className="ln-u-hard">
        <CancelButton onClick={handleClose} />
      </GridItem>
      <Body1>
        {bodyText}
      </Body1>
      <ModalHeading element="h5" className="ln-u-margin-top*2">
        How satisfied are you with this tool?
      </ModalHeading>
      <GridWrapper>
        {satisfactionLevels.map((satisfactionLevel, index) => (
          <GridItem size={{ xs: '1/5' }} className="radio-toolbar" key={`satlevel-${satisfactionLevel[0]}`}>
            <div className="feedback-satisfactionLevel">
              <input
                type="radio"
                id={index.toString()}
                name="radioFruit"
                value={satisfactionLevel[0]}
                onClick={() => setRating(satisfactionLevel[0].toString())}
              />
              <label htmlFor={index.toString()}>
                <span
                  aria-label="aria-label"
                  role="img"
                  className="feedback-emojiImage"
                >
                  {satisfactionLevel[1]}
                </span>
                <div className="feedback-emojiButtonName ln-u-padding-top">
                  {satisfactionLevel[3]}
                </div>
              </label>
            </div>
          </GridItem>
        ))}
      </GridWrapper>
      {showError ? (
        <div className="feedback-error ln-u-font-weight-bold ln-u-margin-top">
          <ErrorCircle className="ln-u-color-red ln-u-display-1 ln-u-margin-right" />
          Please rate the app before submitting
        </div>
      ) : (
        <div />
      )}
      <ModalHeading element="h5" className="ln-u-margin-top*2">
        How could we improve it? (optional)
      </ModalHeading>
      <TextAreaField
        name="Comments"
        placeholder="Add any additional comments here ..."
        className="feedback-inputField ln-u-margin-top"
        onChange={handleChange}
      />
      <FilledButton
        fullWidth
        id="submit-button"
        className="feedback-submitButton ln-u-margin-bottom*2"
        onClick={handleValidation}
        disabled={disabledButton}
      >
        Submit
      </FilledButton>
      <TextButton
        fullWidth
        id="maybe-later-button"
        className="feedback-closeButton"
        onClick={handleClose}
        disabled={disabledButton}
      >
        Maybe Later
      </TextButton>
    </Modal>
  )
})

export default React.memo(Feedback)