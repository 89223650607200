import ApiClient from './ApiClient'
import { ContractorDetails } from '../types/Entities/ContractorEscalation'

const getallcontractordetails = async (apiClient: ApiClient):
  Promise<[ContractorDetails]> => {
  return apiClient
    .get<[ContractorDetails]>(`NewContractor/getallcontractordetails`)
    .catch(error => { throw error })
}

const databasetableApi = { getallcontractordetails }

export default databasetableApi