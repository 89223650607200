import { Modal as ReactModal, ModalHeading } from '@jsluna/react'
import {
  FilledButton,
  OutlinedButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'

interface Props {
  isOpen: boolean;
  heading: string;
  body: string;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
  handlePrimaryButtonClick: (event: Event) => void;
  handleSecondaryButtonClick: (event: Event) => void;
}

const Modal = (props: Props) => {
  return (
    <ReactModal restrictClose alert open={props.isOpen} className='modal'>

      <h3 id={"modal-1"}>{props.heading}</h3>
      <p style={{minWidth: '40vw'}}>{props.body}</p>
      <ButtonGroupWrapper>
        <ButtonGroupPrimary>
          <FilledButton onClick={props.handlePrimaryButtonClick}>{props.primaryButtonTitle}</FilledButton>
        </ButtonGroupPrimary>
        <ButtonGroupSecondary>
          <OutlinedButton onClick={props.handleSecondaryButtonClick}>{props.secondaryButtonTitle}</OutlinedButton>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </ReactModal>
  )
}

export default Modal