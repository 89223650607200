import ApiClient from './ApiClient'
import { ContractorManagementType } from '../types/Entities/ContractorManagementType'

const getContractorManagement = async (apiClient: ApiClient, id: string):
  Promise<ContractorManagementType> => {
  return apiClient
    .get<ContractorManagementType>(`ContractorManagement/get?id=${id}`)
    .catch(error => { throw error })
}

const updateContractorManagement = async (apiClient: ApiClient, payload: ContractorManagementType):
  Promise<Response> => {
  return apiClient
    .post('ContractorManagement', JSON.stringify(payload))
    .catch(error => { throw error })
}

const contractorManagementApi = {
  getContractorManagement, 
  updateContractorManagement
}

export default contractorManagementApi