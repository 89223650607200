import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container, SiteLayout } from '@jsluna/react'

import PageHeader from '../Common/Header'

interface HomePageProps {
  roles: string[]
}

const HomePage = ({ roles }: HomePageProps) => {
  return (
    <SiteLayout stickyHeader className='page'>
      <PageHeader roles={roles} />
      <Container className='content' soft size='lg'>
        <Outlet />
      </Container>
    </SiteLayout>
  )
}

export default HomePage