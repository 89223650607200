import React, { useEffect, useState } from 'react'
import { TextInputField } from '@jsluna/react'
import { Button, IconButton } from '@jsluna/button'

import { ReactComponent as UserPlus } from '../../../assets/icons/user-plus-solid.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-icon.svg'

import { isEmail } from '../../../utils/helpers'

interface Props {
  values: string[];
  maxValues: number;
  placeholderText?: string;
  name: string;
  updateHandler: (values: string[]) => void;
  parentError?: string;
  clearParentError?: () => void;
}

const MultipleTextInput = ({
  values, maxValues, placeholderText = '', name, updateHandler,
  parentError = '', clearParentError = () => { }
}: Props) => {

  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const [isInputOpen, setIsInputOpen] = useState(true)

  useEffect(() => {
    setIsInputOpen(values.length > 0 && value === '' ? false : true)
  }, [values, value])

  const handleAddButtonClick = (event: Event) => {
    event.preventDefault()
    setIsInputOpen(true)
  }

  const handleTextInput = () => {
    var inputValue = value.trim()
    if (inputValue && isValid(value)) {
      updateHandler([...values, inputValue])
      setValue('')
      setError('')
      clearParentError()
      setIsInputOpen(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    setError('')
    clearParentError()
  }

  const handleDelete = (item: string) => {
    updateHandler(values.filter(val => val !== item))
  }

  const handleEdit = (item: string) => {
    handleDelete(item)
    setValue(item)
    setIsInputOpen(true)
  }

  const isValid = (email: string) => {
    let error = ''

    if (values.includes(email)) {
      error = `${email} has already been added.`
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`
    }

    if (error !== '') {
      setError(error)
      return false
    }

    return true
  }

  return (
    <>
      {values.length > 0 &&
        <div className='multiple-input-wrapper'>
          <div className='multiple-inputs'>
            {values.map(val => (
              <div className='input-tag-item' key={val}>
                {val}
                <button type='button' className='input-tag-item-button' onClick={() => handleEdit(val)}>
                  <EditIcon className='input-tag-item-button-icon' />
                </button>
                <button type='button' className='input-tag-item-button' onClick={() => handleDelete(val)}>
                  <CrossIcon className='input-tag-item-button-icon' />
                </button>
              </div>
            ))}
          </div>
          {(values.length < maxValues && !isInputOpen) &&
            <IconButton className='ln-u-text-align-right' label="Add Escalation Contact" hideLabel align="right" onClick={handleAddButtonClick}>
              <UserPlus />
            </IconButton>
          }
        </div>
      }
      {(values.length < maxValues && isInputOpen) &&
        <TextInputField
          name={name}
          className='multiple-text-input-box'
          placeholder={placeholderText}
          value={value}
          onChange={handleChange}
          action={
            <Button onClick={handleTextInput}>
              <UserPlus />
            </Button>
          }
          error={parentError !== '' ? parentError : error}
        />
      }
    </>
  )
}

export default MultipleTextInput